// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.hero {
  background-image: url("/images/CypressHalf.jpg");
  background-position: center center;
  background-size: cover;
  padding: $spacer * 2;
}
.card {
  background: $white;
}
.logo--security {
  max-width: 200px;
  margin: auto;
  padding: $spacer;
}
@media screen and (min-width: $grid-md) {
  .hero {
    background-image: url("/images/CypressFull.jpg");
  }
}
.video_wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.video_wrapper--port {
  padding-bottom: 120.5%; /* 9:16 */
}
