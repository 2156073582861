.comments {
  form {
    align-self: flex-end;
    text-align: right;
  }
}
.comment {
  background: $white;
  padding: $spacer;
  margin-bottom: $spacer;
  // &__bio {
  //   display: none;
  // }
  &__author {
    text-align: right;
    color: $grey;
    p {
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $grid-md) {
  .comments {
    form {
      width: 50%;
    }
  }
  .comment {
    display: flex;
    flex-wrap: wrap;
    &__bio {
      display: inline-block;
      flex-basis: 66px;
      // flex-grow: 1;
      &_init {
        line-height: 50px;
        margin: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        background: $third-color;
        border-radius: 50%;
      }
    }
    &__content {
      display: inline-block;
      flex-basis: 80%;
      flex-grow: 1;
    }
    &__author {
      display: inline-block;
      flex-basis: 100%;
      flex-grow: 1;
      form {
        width: 100%;
        margin: 0;
      }
    }
  }
}
