/**
 * Basic typography style for copy text
 */
body {
  // color: $text-color;
  font-family: $text-font-stack;
}

h1,
h2,
h3,
h4 {
  font-family: "Dosis", sans-serif;
  // text-transform: uppercase;
  line-height: 1;
}
h1,
h2 {
  text-transform: uppercase;
}
h1 {
  font-weight: 300;
  font-size: 2.074rem;
  margin-bottom: 2.074rem;
  text-align: center;
}
h2 {
  font-weight: 700;
  font-size: 1.728rem;
  margin-bottom: 1.728rem;
}
h3 {
  font-weight: 700;
  font-size: 1.44rem;
  margin-bottom: 1.44rem;
}
h4 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
}
h5 {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 1rem;
}
h6 {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 1rem;
}
a {
  color: $link-color;
  transition: all $animation;
  &:hover {
    color: darken($link-color, 20%);
    transition: all $animation;
  }
}
.a__light {
  color: $grey;
  &:hover {
    color: darken($link-color, 20%);
  }
}
.text--right {
  text-align: right;
}
.text--left {
  text-align: left;
}
.text--center {
  text-align: center;
}
.text--no_margin {
  margin: 0;
}
.h1--slogan {
  font-family: $text-font-stack;
  color: $white;
  text-align: left;
  line-height: 1.5;
}
@media screen and (min-width: $grid-md) {
  .h1--slogan {
    font-size: 2rem;
  }
}
@media screen and (min-width: $grid-lg) {
  .h1--slogan {
    font-size: 4rem;
  }
}
.text--secondary_color {
  color: $secondary-color;
}
.text--danger {
  color: $red;
}
