// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  background: $main-color;
  color: $grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $spacer;
}
nav {
  text-align: right;
  ul {
    margin: 0;
    padding: 0;
  }
  ul li {
    display: inline-block;
    margin-right: 0.5rem;
    text-align: center;
  }
}
#logo {
  max-width: 150px;
  margin-bottom: $spacer;
}

@media only screen and (min-width: 768px) {
  header {
    flex-direction: row;
  }
  #logo {
    margin-bottom: 0;
  }
}
