// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
body {
  // background: $grey;
}
li {
  list-style: none;
}
img {
  max-width: 100%;
}
.img--thumb {
  max-width: 200px;
}
span {
  position: relative;
}
