body {
  // display: flex;
  // flex-direction: column;
}
.hero {
  position: relative;
}
.container {
  // width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  // padding: $base-padding;
}
/* 
base layout - 2 columns
*/
.block {
  display: flex;
  flex-direction: column;
  // margin-bottom: $spacer;
  padding: $base-padding;
  flex-basis: 100%;
}
// elements
.block__row {
  flex-direction: row;
}
.block__all_center {
  align-items: center;
}
// Modifers
.block--full {
  flex-basis: 100%;
}
.block--no_padding {
  padding: 0;
}
.align__right {
  align-self: flex-end;
}
.align__start {
  align-items: flex-start;
}
.wrap__none {
  flex-wrap: nowrap;
}
.wrap {
  flex-wrap: wrap;
}
.justify--start {
  justify-content: start;
}
.justify--center {
  justify-content: center;
}
.justify--end {
  justify-content: flex-end;
}
.align__center {
  align-self: center;
}

@media screen and (min-width: $grid-md) {
  .container {
    flex-direction: row;
    flex-wrap: wrap;
    // padding: 0;
  }
  .block {
    padding: $base-padding;
    flex-basis: 50%;
  }
  .block--third {
    flex-basis: 33%;
  }
  .block--full {
    flex-basis: 100%;
  }
  .block--no_padding {
    padding: 0;
  }
}

/* 
3 column layout
Start with mobile layout, forcing it to still take up 50%
*/
.grid--thirds {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: $spacer;
  // padding: $base-padding;
}
.grid--thirds__col {
  flex-basis: 100%;
  margin-bottom: $spacer;
  padding: $base-padding;
}
.grid--thirds__col2 {
  margin-bottom: $spacer;
  padding: $base-padding;
  flex-basis: 100%;
}
.grid--thirds__col3 {
  margin-bottom: $spacer;
  padding: $base-padding;
  flex-basis: 100%;
}
// .block--thirds {
//   flex-basis: 50%;
// }
.form__row--thirds {
  &__el {
    flex-basis: 33%;
    // align-items: center;
    // padding: $base-padding;
    // justify-content: space-around;
  }
  .form__row--half {
    &__el {
      flex-basis: 50%;
      // align-items: center;
      // padding: $base-padding;
      // justify-content: space-around;
    }
  }
}

@media screen and (min-width: $grid-md) {
  // .block--third {
  //   flex-basis: 32%;
  // }
  // .block--thirds {
  //   flex-basis: 33%;
  // }
  .grid--thirds__col {
    flex-basis: 33%;
  }
  .grid--thirds__col2 {
    flex-basis: 66%;
  }
}

.row_thirds {
  display: flex;
  flex-direction: row;
  padding: $base-padding;
  margin-bottom: $spacer;
  justify-content: space-around;
  flex-basis: 100%;
  flex-wrap: wrap;
  &__el {
    align-items: center;
    padding: $base-padding;
    justify-content: space-around;
  }
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
  margin-bottom: $spacer;
}
