form {
  margin-bottom: $spacer;
}
.form--column {
  flex-direction: column;
}
.card__form {
  // flex-direction: column;
  // display: flex;
  // margin: 0 auto;
  padding: $spacer;
  border-radius: $border-radius;
  background: $secondary-color;
  // max-width: 400px;
  color: $grey;
}
.form__login {
  display: flex;
  flex-direction: column;
}
.input--search {
  text-align: right;
}
input,
select,
textarea {
  margin-bottom: $spacer;
}
input,
select,
textarea {
  border: $border;
  border-radius: $border-radius;
  padding: $spacer/2;
}
input,
textarea {
  background: rgba($grey, 0.2);
  &::placeholder {
    color: $mid-grey;
  }
  &:disabled {
    color: $mid-grey;
  }
}
input[type="radio"] {
  margin-right: $spacer/2;
}
input[type="checkbox"] {
  margin-right: $spacer/2;
}
select {
}
textarea {
  width: 100%;
  height: 100px;
}
.form {
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
}
@media screen and (min-width: $grid-md) {
  .form {
    display: flex;
    flex-wrap: wrap;
  }
}
.form__block {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacer;
  padding: $base-padding;
  flex-basis: 50%;
}
.form__row {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.form__row--space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-between {
  justify-content: space-between;
}
.note {
  font-size: 0.75rem;
  margin-left: $spacer/2;
  vertical-align: super;
  color: $highlight-color;
  text-transform: lowercase;
}
.input--small {
  width: 70px;
}
