ul {
  padding-left: 0;
}
.list {
  display: flex;
  flex-wrap: wrap;
  background: $white;
  padding: $spacer/2 $spacer;
  position: relative;
  justify-content: space-between;
}
.list--header {
  background: none;
  font-weight: 700;
}
.list--button {
  cursor: pointer;
  transition: all $animation;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: all $animation;
  }
  &:hover:before {
    opacity: 1;
    transition: all $animation;
  }
  &:after {
    opacity: 0;
    content: 'Submit New';
    font-size: 1.5rem;
    position: absolute;
    // top: 0;
    right: 0;
    left: 0;
    // bottom: 0;
    z-index: 10;
    text-align: center;
    color: $white;
    transition: all $animation;
  }
  &:hover:after {
    opacity: 1;
    transition: all $animation;
  }
}
.list__item {
  z-index: 100;
  position: relative;
  flex-basis: 180px;
  padding: $spacer / 4;
  margin: $spacer / 4;
  // display: flex;
  // span {
  //   position: relative;
  // }
}
.list__item--half {
  flex-basis: calc(50% - 0.5rem);
}
.list__item--full {
  justify-content: space-between;
  display: flex;
  flex-basis: 100%;
}
.list__item--progress {
}

.list__pbar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  // z-index: 0;
  background: $green;
  width: 100%;
}
@for $i from 0 through 5 {
  .list__pbar[data-complete='#{$i}'] {
    width: 100% * ($i / 5);
  }
}
@for $i from 0 through 1 {
  .list__pbar--toggle[data-complete='#{$i}'] {
    width: 100% * ($i / 1);
  }
}
@for $i from 0 through 10 {
  .list__pbar--ten[data-complete='#{$i}'] {
    width: 100% * ($i / 10);
  }
}

#sort-filter.asc:after {
  content: 'Ascending \25B2';
  display: inline-block;
  padding: 0 0.5em;
  color: $highlight-color;
}
#sort-filter.desc:after {
  content: 'Descending \25BC';
  display: inline-block;
  padding: 0 0.5em;
  color: $highlight-color;
}
@media screen and (min-width: $grid-md) {
  .list__item {
    // flex-basis: 33%;
  }
}
@media screen and (min-width: $grid-lg) {
  .list__item {
    // flex-basis: 25%;
  }
}
.tooltip {
  position: absolute;
  top: -22px;
  right: 0px;
  color: $green;
  background: $white;
  padding: $spacer/2;
  border: $border-radius;
}
.copied {
}

.list--accordion {
  padding: 0 $spacer;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height $animation;
}
.button--accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.button--accordion.active:after {
  content: '\2796'; /* Unicode character for "minus" sign (-) */
}
