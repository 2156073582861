.docGallery {
}
.docGallery__item {
  span {
    position: absolute;
    background: $secondary-color;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: $animation;
    padding-top: 25%;
    text-align: center;
    &:hover {
      transition: $animation;
      opacity: 0.7;
    }
  }
  position: relative;
  // width: 33%;
  &_image {
    position: relative;
  }
  &_controls {
    text-align: right;
  }
}
