// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
button {
  background: $third-color;
  color: $main-color;
  border: none;
  border-radius: $border-radius;
  padding: $spacer/2 $spacer;
  transition: all $animation;
  &:hover {
    cursor: pointer;
    color: $third-color;
    background: $main-color;
    transition: all $animation;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.button--icon {
  background: none;
  border: none;
  outline: none;
  color: $link-color;
  padding: $spacer/4;
  &:hover {
    background: none;
    color: $highlight-color;
  }
}
.button--alt {
  background: $secondary-color;
  color: $white;
}
.button--danger {
  background: $red;
  color: $white;
  &:hover {
    background: darken($red, 30%);
    color: $white;
  }
}
.button--delete {
  &:after {
    content: "";
  }
  &:hover:after {
    content: " Delete!";
  }
}
.button--back {
  background-color: $third-color;
  position: fixed;
  bottom: $spacer;
  left: $spacer;
  padding: $spacer;
}
.CoRDiagram {
  background: none;
  // border: 1px solid $danger;
  padding: 0px;
  margin: 0px;
  height: 100%;
  &:hover {
    background: $green;
  }
  img {
    // max-width: inherit;
  }
}
