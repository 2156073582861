// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
  background: $third-color;
  color: $main-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacer;
}