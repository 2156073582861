.search,
.userSearch {
  position: relative;
  width: 100%;
  display: flex;
  &__results {
    background: white;
    // position: absolute;
    width: 100%;
    // top: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 101;
  }
  &__input {
    font-size: 30px;
    background: none;
    color: white;
    outline: 0;
    border: 0;
  }
  &__result {
    padding: 10px;
    display: block;
    border-bottom: 1px solid #ececec;
    &--active {
      background: #f1f1f1;
    }
  }
}
@media screen and (min-width: $grid-md) {
  .search,
  .userSearch {
    &__results {
      position: absolute;
      top: 100%;
      width: 92%;
    }
  }
}
