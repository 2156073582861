.license_tile {
  background: $grey;
  border: $border;
  border-radius: $border-radius;
  padding: $spacer;
  text-align: center;
}
.radio_container {
  position: relative;
  // height: 10rem;
  width: 10rem;
  margin: 0.5rem;
}
.radio_button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
  transition: all $animation;
}
.radio_button:checked + .license_tile {
  background: $white;
  border: 2px solid $green;
  transform: scale(1.1, 1.1);
  transition: all $animation;
}
