.modal__overlay {
  background: rgba($black, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1010;
  // display: flex;
}
.modal__inner {
  border-radius: $border-radius;
  background: $white;
  display: flex;
  padding: $spacer;
  flex-direction: column;
  align-items: center;
}
.modal--closed {
  display: none;
}
.button__close {
  background: none;
  color: $highlight-color;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;
  margin-right: 0px;
}
