.label {
  background: $highlight-color;
  border-radius: $border-radius;
  color: $white;
  font-size: 16px;
  padding: 2px 5px;
  margin: 0.5rem 0.25rem 0rem 0.25rem;
  // margin-left: 10px;
  // margin-top: 5px;
  vertical-align: super;
  &--warn {
    background: $yellow;
    color: $main-color;
  }
  &--success {
    background: $success;
    color: $white;
  }
  &--normal {
    vertical-align: inherit;
  }
}
