@charset 'UTF-8';
@import url('https://fonts.googleapis.com/css?family=Dosis:300,700|Roboto:400,700');

// 1. Configuration and helpers
@import 'vendor/rebootVars', 'abstracts/variables', 'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import 'vendor/reboot', 'vendor//braintree';
// 'vendor/normalize';

// 3. Base stuff
@import 'base/base', 'base/fonts', 'base/typography', 'base/helpers';

// 4. Layout-related sections
@import 'layout/footer', 'layout/forms', 'layout/grid', 'layout/header';

// 5. Components
@import 'components/lists', 'components/search', 'components/cards_tiles',
  'components/comments', 'components/flash', 'components/modal',
  'components/gallery', 'components/labels', 'components/button';

// 6. Page-specific styles
@import 'pages/home';

// 7. Themes
@import 'themes/default';
