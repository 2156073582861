// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Roboto", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace !default;

/// Color Pallette
/// @type Color
$main-color: rgb(80, 98, 102); // #506266 - dark grayish
$secondary-color: rgb(62, 116, 127); // #3E747F - teal
$third-color: rgb(140, 171, 178); // #8CABB2 - light gray blue
$highlight-color: rgb(102, 39, 33); // #662721 - dark red
$black: rgb(18, 18, 18);
$grey: rgb(210, 210, 210); // #D2D2D2
$yellow: rgb(242, 201, 76); // #F2C94C;

// /// Copy text color
// /// @type Color
// $text-color: $black !default; //black #121212

// /// Main brand color
// /// @type Color
// $brand-color: $main-color !default;

// /// Light grey
// /// @type Color
$light-grey: rgb(237, 237, 237) !default;

// /// Medium grey
// /// @type Color
$mid-grey: rgb(153, 153, 153) !default;

// /// Dark grey
// /// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
// $max-width: 1180px !default;

$border-radius: 2px;
$border: 1px solid $main-color;
$base-padding: 1rem;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
// $breakpoints: (
//   'small': 320px,
//   'medium': 768px,
//   'large': 1024px,
// ) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/assets/" !default;

// Override the reboot stuff

//
// Color system
//

// stylelint-disable
// $white:    #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #868e96 !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black:    #000 !default;

// $grays: () !default;
// $grays: map-merge((
//   "100": $gray-100,
//   "200": $gray-200,
//   "300": $gray-300,
//   "400": $gray-400,
//   "500": $gray-500,
//   "600": $gray-600,
//   "700": $gray-700,
//   "800": $gray-800,
//   "900": $gray-900
// ), $grays);

// $blue:    #007bff !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
$green: #6fcf97;
// $teal:    #20c997 !default;
// $cyan:    #17a2b8 !default;

// $colors: () !default;
// $colors: map-merge((
//   "blue":       $blue,
//   "indigo":     $indigo,
//   "purple":     $purple,
//   "pink":       $pink,
//   "red":        $red,
//   "orange":     $orange,
//   "yellow":     $yellow,
//   "green":      $green,
//   "teal":       $teal,
//   "cyan":       $cyan,
//   "white":      $white,
//   "gray":       $gray-600,
//   "gray-dark":  $gray-800
// ), $colors);

// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// $theme-colors: () !default;
// $theme-colors: map-merge((
//   "primary":    $primary,
//   "secondary":  $secondary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
// ), $theme-colors);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// $enable-caret:              true !default;
// $enable-rounded:            true !default;
// $enable-shadows:            false !default;
// $enable-gradients:          false !default;
// $enable-transitions:        true !default;
// $enable-hover-media-query:  false !default;
// $enable-grid-classes:       true !default;
// $enable-print-styles:       true !default;

//variables needed to get reboot to work without bootstrap
$font-family-base: "Roboto", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
// stylelint-enable value-keyword-case
// $font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
// $font-weight-normal: 400 !default;
// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;
// $white:    #fff !default;
$body-bg: #f2f2f2;
// $gray-900: #212529 !default;
// $body-color: $gray-900 !default;
// $spacer: 1rem !default;
// $headings-margin-bottom: ($spacer / 2) !default;
// $paragraph-margin-bottom:   1rem !default;
// $font-weight-bold: 700 !default;
// $dt-font-weight: $font-weight-bold !default;

$link-color: lighten($highlight-color, 30%);
$link-decoration: none;
// $link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none;
// $table-cell-padding: .75rem !default;
// $text-muted: $gray-600 !default;

$grid-md: 768px;
$grid-lg: 1024px;

$animation: linear 200ms;
